
.content {
  display: flex;
align-items: center;
margin-bottom: 10px;
padding: 20px 0;
border-radius: 5px;
margin: 0 auto;
}

.mr-10{
  margin-right: 10px;
}

.icon-image {
  width:50px;
  height: 50px
  
}
.color-white {
  color: #fff;
}
.color-heading {
  color: #f5c801
}

.font-25{
  font-size: 25px;
}