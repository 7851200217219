.main {
  background: url("../src/assets/Background.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 1rem;
}

.reviews {
  margin-top: 5rem !important;
}

.review-flex {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

.review-img {
  width: 100%;
}
