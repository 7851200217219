.my-component {
  background-color: #bedff8;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: Helvetica;
  margin: 0 auto;
}

.my-component-heading {
  font-size: 28px;
  font-weight: bold;
}
