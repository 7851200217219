.button_style {
  background-color: #fca22d;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  justify-content: center;
  width: 80%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  font-weight: bold;
}

.icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  color: #fff;
  fill: #fff;
}

.h1 {
  color: #fff;
  font-family: Helvetica;
  font-size: 26px;
}
