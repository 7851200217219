.forecast-title {
  font-size: 15px;
  font-family: Helvetica;
}

.forecast-box {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.forecast-flex-container {
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.forecast-input {
  outline: none;
  font-size: 16px;
  border: 1px solid #d7e3fd;
  padding: 10px;
  border-radius: 10px;
  font-family: Helvetica;
  width: 78%;
}

.forecast-icon {
  height: 34px;
  width: 24px;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
}

.title-and-input {
  width: 100%;
}

.button-flex-container {
  margin-top: 23px;
  display: flex;
  justify-content: center;
}
