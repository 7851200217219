.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 50px;
  max-width: 80%;
  margin: 0 auto;
}

.bg-lightgrey {
  background-color: #fafaf7;
}
