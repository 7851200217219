.contentCard {
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.board-card-title {
  font-size: 16px;
}

.image-div {
  width: 16rem;
  height: 8rem;
}

.image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
