.header {
  text-align: center;
  font-size: 22px;
  padding: 5px 0;
  background-color: red;
}

.color-heading {
  color: #f5c801;
}
.color-white {
  color: #fff;
}

.banner {
  padding: 8px;
  font-size: 18px;
}
