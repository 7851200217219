.titleStyle {
  font-size: 22px;
  margin: 0 0 40px 0;
  font-family: Helvetica;
  text-align: center;
}

.p-info {
  color: #bebeb6;
  text-align: center;
  margin-top: 30px;
}

.p-link {
  text-align: center;
  color: #bebeb6;
  cursor: pointer;
  text-decoration-line: underline;
  text-underline-offset: 5px;
  text-decoration-color: #bebeb641;
}

.p-data {
  color: #bebeb6;
  text-align: center;
}
