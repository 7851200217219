.gridStyle {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  display: flex;
  background-color: #dceafb;
  padding: 30px 30px 50px 30px;
  align-items: center;
  column-gap: 16px;
  justify-content: space-evenly;
  border-radius: 5px;
}
